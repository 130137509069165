<template>
  <small>
    <br />
    <span class="text-blue-600 line-through">
      <slot></slot>
    </span>
  </small>
</template>
<script setup lang="ts"></script>
<style scoped></style>
