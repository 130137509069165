<template>
  <div>
    <SectionHeading
      :title="viewType === 'new' ? 'New' : 'Edit'"
      iconName="OfficeBuildingIcon"
      class="mb-4"
    />
    <LoadingSpinner v-if="viewType === 'edit' && dataLoading" />
    <FormSection
      @submitted="viewType === 'new' ? addProduct() : updateProduct()"
    >
      <template #title>
        {{ $t('views.catalog.products.title') }}
      </template>

      <template #description>Manage your product catalog</template>

      <template #form>
        <div class="col-span-6 xl:col-span-6">
          <div class="grid grid-rows-1 grid-flow-col">
            <div
              class="relative h-40 w-40 flex-shrink-0 overflow-hidden bg-gray-200 sm:h-40 sm:w-40 border border-gray-200 shadow-md"
            >
              <img
                :src="mediaData.url"
                class="h-40 w-40 flex-nonebg-gray-100 object-cover object-center"
              />
              <DynamicIconSolid
                @click="removeImage"
                icon="TrashIcon"
                class="absolute text-red-500 h-8 w-8 p-1.5 cursor-pointer z-10 top-1 left-1 bg-white rounded-full shadow-md"
                aria-hidden="true"
              />
            </div>
          </div>
          <div class="">
            <input
              type="file"
              @change="saveImageToStore"
              :class="[
                'mt-3 rounded-md block w-full border',
                'border-gray-300 bg-white',
                'py-2 px-3 text-sm font-medium leading-4',
                'text-gray-700 shadow-sm hover:bg-gray-50',
                'focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
              ]"
            />
            <InputError :message="mediaData.error" class="mt-2" />
          </div>
        </div>

        <div class="col-span-6 xl:col-span-3">
          <Label for="lower_age_limit" value="Wiek" />
          <Input
            id="lower_age_limit"
            type="number"
            class="mt-1 block w-full"
            v-model="productData.lower_age_limit"
          />
          <InputError
            :message="productData.errors.get('lower_age_limit')"
            class="mt-2"
          />
        </div>

        <div class="col-span-6 xl:col-span-3">
          <Label for="categories_ids" value="Kategorie" />
          <TRichSelect
            id="categories_ids"
            v-model="productData.categories_ids"
            :fetchOptions="categoriesFetchOptions"
            :prefetchOptions="categoriesPrefetchOptions"
            textAttribute="name"
            valueAttribute="id"
            placeholder="Wybierz kategorię"
            multiple
            tags
            class="mt-1 block w-full"
          />
          <InputError
            :message="productData.errors.get('categories_ids')"
            class="mt-2"
          />
        </div>

        <div class="col-span-6 xl:col-span-3">
          <Label for="unit_id" value="Jednostka" />
          <TSelect
            id="unit_id"
            :options="unitsList.data"
            class="mt-1 block w-full"
            v-model="productData.unit_id"
            valueAttribute="id"
            textAttribute="name"
          />
          <InputError
            :message="productData.errors.get('unit_id')"
            class="mt-2"
          />
        </div>

        <div class="col-span-6 xl:col-span-3">
          <Label for="size" value="Rozmiar" />
          <Input
            id="size"
            type="number"
            class="mt-1 block w-full"
            v-model="productData.size"
          />
          <InputError :message="productData.errors.get('size')" class="mt-2" />
        </div>

        <div class="col-span-6 xl:col-span-3">
          <Label for="pack_type_id" value="Rodzaj opakowania" />
          <TSelect
            id="pack_type_id"
            :options="packTypesList.data"
            class="mt-1 block w-full"
            v-model="productData.pack_type_id"
            valueAttribute="id"
            textAttribute="name"
          />
          <InputError
            :message="productData.errors.get('pack_type_id')"
            class="mt-2"
          />
        </div>

        <div class="col-span-6 xl:col-span-3">
          <Label for="weight" value="Waga" />
          <Input
            id="weight"
            type="number"
            class="mt-1 block w-full"
            v-model="productData.weight"
          />
          <InputError
            :message="productData.errors.get('weight')"
            class="mt-2"
          />
        </div>

        <div class="col-span-6 xl:col-span-3">
          <Label for="product_ingredient_ids" value="Składniki produktu" />
          <TRichSelect
            id="product_ingredient_ids"
            :fetchOptions="productIngredientsFetchOptions"
            :prefetchOptions="productIngredientsPrefetchOptions"
            class="mt-1 block w-full"
            v-model="productData.product_ingredient_ids"
            valueAttribute="id"
            textAttribute="name"
            multiple
            tags
          />
          <InputError
            :message="productData.errors.get('product_ingredient_ids')"
            class="mt-2"
          />
        </div>

        <div class="col-span-6 xl:col-span-3">
          <Label for="recipe_ingredient_ids" value="Składniki z przepisów" />
          <TRichSelect
            id="recipe_ingredient_ids"
            :fetchOptions="recipeIngredientsFetchOptions"
            :prefetchOptions="recipeIngredientsPrefetchOptions"
            class="mt-1 block w-full"
            v-model="productData.recipe_ingredient_ids"
            valueAttribute="id"
            textAttribute="name"
            multiple
            tags
          />
          <InputError
            :message="productData.errors.get('recipe_ingredient_ids')"
            class="mt-2"
          />
        </div>

        <SectionBorder class="col-span-6" />

        <div
          v-for="(variant, i) in productData.variants"
          :key="i"
          class="col-span-6 grid grid-cols-1 xl:grid-cols-2 gap-6"
        >
          <div>
            <Label value="GTIN (EAN)" />
            <Input
              type="text"
              class="mt-1 block w-full"
              v-model="productData.variants[i].gtin"
            />
            <InputError
              :message="productData.errors.get('variants.' + i + '.gtin')"
              class="mt-2"
            />
          </div>
          <div>
            <Label value="SKU" />
            <Input
              type="text"
              class="mt-1 block w-full"
              v-model="productData.variants[i].sku"
            />
            <InputError
              :message="productData.errors.get('variants.' + i + '.sku')"
              class="mt-2"
            />
          </div>
          <div>
            <Label value="Grupa stawki VAT" />
            <TSelect
              :options="taxGroupsList.data"
              class="mt-1 block w-full"
              v-model="productData.variants[i].tax_group_id"
              valueAttribute="id"
              textAttribute="name"
            />
            <InputError
              :message="
                productData.errors.get('variants.' + i + '.tax_group_id')
              "
              class="mt-2"
            />
          </div>
          <div>
            <Label value="Liczba sztuk w wariancie" />
            <Input
              type="number"
              class="mt-1 block w-full"
              v-model="productData.variants[i].pcs"
            />
            <InputError
              :message="productData.errors.get('variants.' + i + '.pcs')"
              class="mt-2"
            />
          </div>

          <table
            class="table-auto divide-y divide-gray-300 col-span-2 border border-gray-200"
          >
            <thead class="bg-gray-50">
              <tr class="text-sm font-semibold text-gray-900">
                <th scope="col" class="py-3.5 text-left pl-4">Magazyn</th>
                <th scope="col" class="py-3.5 text-center">Cena</th>
                <th scope="col" class="py-3.5 text-right pr-4">
                  Aktualny stan magazynowy
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                v-for="(depot, j) in productData.variants[i].depots"
                :key="j"
                class="text-sm"
              >
                <td
                  class="whitespace-nowrap py-4 pl-4 font-medium text-gray-900 text-left"
                >
                  {{ productData.variants[i].depots[j].name }}
                </td>
                <td class="whitespace-nowrap py-4 text-gray-500 text-center">
                  {{ productData.variants[i].depots[j].price }}
                </td>
                <td
                  class="whitespace-nowrap py-4 pr-4 text-gray-500 text-right"
                >
                  {{ productData.variants[i].depots[j].stocks }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <SectionBorder class="col-span-6" />

        <div class="col-span-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2">
            Tłumaczenia
          </h3>

          <TabGroup>
            <TabList class="flex flex-row bg-gray-100 rounded-t-md">
              <Tab
                v-for="translation in TRANSLATIONS"
                as="div"
                :key="translation.id"
                v-slot="{ selected }"
              >
                <div
                  :class="[
                    selected
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                    'flex whitespace-nowrap border-b-2 py-4 px-6 text-sm font-medium',
                    'cursor-pointer',
                  ]"
                >
                  {{ translation.label }}
                  <span
                    v-if="
                      productData.errors.get(
                        `translations.${translation.value}.name`
                      )
                    "
                    :class="[
                      'bg-red-100 text-red-600',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-bold md:inline-block animate-pulse',
                    ]"
                    >!
                  </span>
                </div>
              </Tab>
            </TabList>
            <TabPanels class="border-2 border-gray-100 rounded-b-md">
              <TabPanel
                v-for="translation in TRANSLATIONS"
                class="flex flex-col gap-2 p-4"
              >
                <div>
                  <Label value="Nazwa" />
                  <Input
                    type="text"
                    class="mt-1 block w-full"
                    :value="
                      inputValue('translations', translation.value, 'name')
                    "
                    @input="
                      inputEvent(
                        $event,
                        'translations',
                        translation.value,
                        'name'
                      )
                    "
                  />
                  <InputError
                    :message="
                      productData.errors.get(
                        `translations.${translation.value}.name`
                      )
                    "
                    class="mt-2"
                  />
                </div>
                <div>
                  <Label value="Nazwa produktu (opis)" />
                  <Input
                    type="text"
                    class="mt-1 block w-full"
                    :value="
                      inputValue(
                        'translations',
                        translation.value,
                        'description'
                      )
                    "
                    @input="
                      inputEvent(
                        $event,
                        'translations',
                        translation.value,
                        'description'
                      )
                    "
                  />
                  <InputError
                    :message="
                      productData.errors.get(
                        `translations.${translation.value}.name`
                      )
                    "
                    class="mt-2"
                  />
                </div>
                <div>
                  <Label value="Nazwa produktu (skrócona)" />
                  <Input
                    type="text"
                    class="mt-1 block w-full"
                    :value="
                      inputValue(
                        'translations',
                        translation.value,
                        'regulated_product_name'
                      )
                    "
                    @input="
                      inputEvent(
                        $event,
                        'translations',
                        translation.value,
                        'regulated_product_name'
                      )
                    "
                  />
                  <InputError
                    :message="
                      productData.errors.get(
                        'translations.' +
                          translation.value +
                          '.regulated_product_name'
                      )
                    "
                    class="mt-2"
                  />
                </div>
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>

        <SectionBorder class="col-span-6" />

        <div class="col-span-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2 mb-6">
            Atrybuty
          </h3>

          <div class="grid grid-cols-2 gap-6">
            <div
              v-for="(attribute, i) in productData.attributes"
              :key="i"
              class="space-y-4 border rounded-md p-3"
            >
              <div>
                <Label value="Attrybut" />
                <TRichSelect
                  :options="attributesList.data"
                  class="mt-1 block w-full"
                  v-model="productData.attributes[i].attribute_id"
                  valueAttribute="id"
                  textAttribute="name"
                />
                <InputError
                  :message="
                    productData.errors.get(`attributes.${i}.attribute_id`)
                  "
                  class="mt-2"
                />
              </div>
              <div
                v-if="
                  productData.attributes[i].attribute_id &&
                  showOptions(productData.attributes[i].attribute_id)
                "
              >
                <Label value="Opcje" />
                <TRichSelect
                  :options="optionsList(productData.attributes[i].attribute_id)"
                  class="mt-1 block w-full"
                  v-model="productData.attributes[i].option_ids"
                  valueAttribute="id"
                  textAttribute="name"
                  multiple
                  tags
                />
                <InputError
                  :message="
                    productData.errors.get('attributes.' + i + '.option_ids')
                  "
                  class="mt-2"
                />
              </div>
              <div v-else-if="productData.attributes[i].translations !== null">
                <TabGroup>
                  <TabList class="flex flex-row bg-gray-100 rounded-t-md">
                    <Tab
                      v-for="translation in TRANSLATIONS"
                      as="div"
                      :key="translation.id"
                      v-slot="{ selected }"
                    >
                      <div
                        :class="[
                          selected
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                          'flex whitespace-nowrap border-b-2 py-4 px-6 text-sm font-medium',
                          'cursor-pointer',
                        ]"
                      >
                        {{ translation.label }}
                        <span
                          v-if="
                            productData.errors.get(
                              `attributes.${i}.translations.${translation.value}.translated_value`
                            )
                          "
                          :class="[
                            'bg-red-100 text-red-600',
                            'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-bold md:inline-block animate-pulse',
                          ]"
                          >!</span
                        >
                      </div>
                    </Tab>
                  </TabList>
                  <TabPanels class="border-2 border-gray-100 rounded-b-md">
                    <TabPanel
                      v-for="translation in TRANSLATIONS"
                      class="flex flex-col gap-2 p-4"
                    >
                      <div>
                        <Label value="Wartość" />
                        <Input
                          type="text"
                          class="mt-1 block w-full"
                          :value="
                            inputValue(
                              'attributes',
                              i,
                              'translations',
                              translation.value,
                              'translated_value'
                            )
                          "
                          @input="
                            inputEvent(
                              $event,
                              'attributes',
                              i,
                              'translations',
                              translation.value,
                              'translated_value'
                            )
                          "
                        />
                        <InputError
                          :message="
                            productData.errors.get(
                              `attributes.${i}.translations.${translation.value}.translated_value`
                            )
                          "
                          class="mt-2"
                        />
                      </div>
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
              <div v-else>
                <Label value="Opcje" />
                <Input
                  type="text"
                  class="mt-1 block w-full"
                  :value="inputValue('attributes', i, 'value')"
                  @input="inputEvent($event, 'attributes', i, 'value')"
                />
                <InputError :message="null" class="mt-2" />
              </div>
              <DynamicIconSolid
                @click="removeAttribute(i)"
                icon="TrashIcon"
                class="text-red-500 h-6 w-6 mt-2 mb-6 cursor-pointer"
                aria-hidden="true"
              />
            </div>
          </div>

          <SecondaryButton class="mt-6" @click="addAttribute">
            <DynamicIconSolid
              icon="PlusIcon"
              class="mr-2 -ml-1 h-5 w-5"
              aria-hidden="true"
            />
            Dodaj atrybut
          </SecondaryButton>
        </div>
      </template>

      <template #actions>
        <ActionMessage :on="productData.recentlySuccessful" class="mr-3">
          Saved.
        </ActionMessage>

        <Button
          :class="{ 'opacity-25': productData.busy }"
          :disabled="productData.busy"
        >
          {{ viewType === 'new' ? 'Add' : 'Update' }}
        </Button>
      </template>
    </FormSection>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue';
import ProductsService from '@/service/products/products.js';
import { useRoute, useRouter } from 'vue-router';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import FormSection from '../../../components/@globals/helpers/FormSection';
import Input from '../../../components/@globals/helpers/Input';
import InputError from '../../../components/@globals/helpers/InputError';
import Button from '../../../components/@globals/helpers/Button';
import Label from '../../../components/@globals/helpers/Label';
import { TRichSelect, TSelect } from '@variantjs/vue';
import { useStore } from 'vuex';
import ActionMessage from '../../../components/@globals/helpers/ActionMessage';
import Form from 'vform';
import SectionBorder from '../../../components/@globals/helpers/SectionBorder';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import { TRANSLATIONS } from '../categories/category-constants';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { get, set } from 'lodash';
import { ATTRIBUTES_WITH_OPTIONS } from '../attributes/attribute-constants';
import MediaService from '@/service/media/media';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import axios from 'axios';

const props = defineProps({
  id: {
    type: String,
    required: false,
  },
});

const router = useRouter();
const store = useStore();
const {
  params: { id },
} = useRoute();

const viewType = computed(() => {
  if (id === 'new') {
    return 'new';
  }

  return 'edit';
});

const dataLoading = ref(false);
const mediaData = ref({
  errors: '',
  url: '/assets/placeholder_image.png',
});

const productData = ref(
  Form.make({
    lower_age_limit: null,
    categories_ids: [],
    unit_id: null,
    size: null,
    pack_type_id: null,
    weight: null,
    translations: {},
    variants: [
      {
        gtin: '',
        sku: '',
        tax_group_id: null,
        pcs: null,
        stocks: null,
      },
    ],
    attributes: [],
    recipe_ingredient_ids: [],
    product_ingredient_ids: [],
    media_ids: [],
  })
);

store.dispatch('getUnits', {});
const unitsList = computed(() => store.getters.getUnits);

store.dispatch('getPackTypes', {});
const packTypesList = computed(() => store.getters.getPackTypes);

store.dispatch('getTaxGroups', {
  paginate: 0,
});
const taxGroupsList = computed(() => store.getters.getTaxGroups);

store.dispatch('getAttributes', {
  paginate: 0,
});
const attributesList = computed(() => store.getters.getAttributes);

const productIngredientsFetchOptions = async (query, nextPage) => {
  try {
    const response = await axios.get(`/panel/api/v1/product-ingredients`, {
      params: {
        keyword: query,
        page: nextPage || 1,
      },
    });

    return {
      results: response.data.data,
      hasMorePages:
        response.meta && response.meta.current_page < response.meta.last_page,
    };
  } catch (error) {
    console.log(error);

    return {
      results: [],
      hasMorePages: false,
    };
  }
};

const productIngredientsPrefetchOptions = async () => {
  const response = await axios.get(`panel/api/v1/product-ingredients`, {
    params: {
      product_id: id === 'new' ? null : id,
    },
  });

  return response.data.data;
};

const recipeIngredientsFetchOptions = async (query, nextPage) => {
  try {
    const response = await axios.get(`/panel/api/v1/recipe-ingredients`, {
      params: {
        keyword: query,
        page: nextPage || 1,
      },
    });

    return {
      results: response.data.data,
      hasMorePages:
        response.meta && response.meta.current_page < response.meta.last_page,
    };
  } catch (error) {
    console.log(error);

    return {
      results: [],
      hasMorePages: false,
    };
  }
};

const recipeIngredientsPrefetchOptions = async () => {
  const response = await axios.get(`panel/api/v1/recipe-ingredients`, {
    params: {
      product_id: id === 'new' ? null : id,
    },
  });

  return response.data.data;
};

const categoriesFetchOptions = async (query, nextPage) => {
  try {
    const response = await axios.get(`/panel/api/v1/categories`, {
      params: {
        only_root: false,
        keyword: query,
        page: nextPage || 1,
      },
    });

    return {
      results: response.data.data.map((c) => ({
        id: c.id,
        name: c.name,
      })),
      hasMorePages:
        response.meta && response.meta.current_page < response.meta.last_page,
    };
  } catch (error) {
    console.log(error);

    return {
      results: [],
      hasMorePages: false,
    };
  }
};

const categoriesPrefetchOptions = async () => {
  const response = await axios.get(`panel/api/v1/categories`, {
    params: {
      only_root: false,
      product_id: id === 'new' ? null : id,
    },
  });

  return response.data.data.map((c) => ({
    id: c.id,
    name: c.name,
  }));
};

function findAttribute(attributeId) {
  return attributesList.value.data.find((a) => a.id === attributeId);
}

function optionsList(attributeId) {
  return findAttribute(attributeId)?.options.map((o) => ({
    id: o.id,
    name: o.translations[localStorage.getItem('app_lang')].name,
  }));
}

function showOptions(attributeId) {
  let type = findAttribute(attributeId)?.type;
  return ATTRIBUTES_WITH_OPTIONS.includes(type);
}

watchEffect(async () => {
  dataLoading.value = true;

  if (viewType.value === 'new') {
    dataLoading.value = false;

    return;
  }
  const response = await ProductsService.getProductDetails(id);
  fillForm(response);
  dataLoading.value = false;
});

function fillForm(response) {
  const patchProductData = response.data.data;

  patchProductData.categories_ids = response.data.data.categories.map(
    (c) => c.id
  );

  patchProductData.product_ingredient_ids =
    response.data.data.product_ingredients.map((p) => p.id);

  patchProductData.recipe_ingredient_ids =
    response.data.data.recipe_ingredients.map((r) => r.id);

  patchProductData.pack_type_id = response.data.data.pack_type?.id;

  patchProductData.unit_id = response.data.data.unit?.id;

  patchProductData.attributes = response.data.data.attributes.map(function (
    attribute
  ) {
    let translations = {};

    if (attribute.value_translations !== null) {
      Object.entries(attribute.value_translations).forEach(function ([
        key,
        translation,
      ]) {
        translations[key] = {
          translated_value: translation.value,
        };
      });
    } else {
      translations = null;
    }

    return {
      attribute_id: attribute.id,
      value: attribute.value,
      translations: translations,
      option_ids: attribute.options.map((o) => o.id),
    };
  });

  patchProductData.variants = response.data.data.variants.map(function (v) {
    v.tax_group_id = v?.tax_group?.id;

    return v;
  });

  //Media
  const mediaUrl = response.data?.data?.media[0]?.variants[0]?.url;
  mediaData.value.url =
    mediaUrl === undefined ? '/assets/placeholder_image.png' : mediaUrl;

  patchProductData.media_ids =
    response.data?.data?.media[0]?.id === undefined
      ? []
      : [response.data?.data?.media[0]?.id];

  productData.value.fill(patchProductData);
}

function addProduct() {
  ProductsService.addProduct(productData.value).then(() => {
    router.push({ name: 'ProductsList' });
  });
}

async function updateProduct() {
  dataLoading.value = true;

  try {
    await ProductsService.updateProduct(props.id, productData.value).then(
      () => {
        router.push({ name: 'ProductsList' });
      }
    );
  } catch (error) {
    console.log(error);
  }

  dataLoading.value = false;
}

function addVariant() {
  productData.value.variants.push({
    gtin: '',
    sku: '',
    tax_group_id: null,
    pcs: null,
    stocks: null,
  });
}

function removeVariant(index) {
  productData.value.variants.splice(index, 1);
}

function translationErrorExist(...path) {
  return Object.keys(productData.value.errors.all()).filter(function (key) {
    return key.includes(path.join('.'));
  }).length;
}

function showTranslation(...path) {
  return get(disclosureState.value, path, false);
}

function inputValue(...fieldName) {
  return get(productData.value, fieldName, null);
}

function inputEvent(event, ...fieldName) {
  set(productData.value, fieldName, event.target.value);
}

function addAttribute() {
  productData.value.attributes.push({
    attribute_id: null,
    value: '',
    translations: {},
    option_ids: [],
  });
}

function removeAttribute(index) {
  productData.value.attributes.splice(index, 1);
}

function saveImageToStore(event) {
  dataLoading.value = true;
  MediaService.addMedia(event, 'product', props.id)
    .then((response) => {
      productData.value.media_ids = [response.data.data[0].id];
      mediaData.value.url = response.data.data[0].variants[0].url;
    })
    .catch((error) => {
      mediaData.value.error = error.response.data.errors['media.0'][0];
    })
    .finally(() => {
      dataLoading.value = false;
    });
}

function removeImage() {
  productData.value.media_ids = [];
  mediaData.value.url = '/assets/placeholder_image.png';
}
</script>
